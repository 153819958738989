import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingSpinner from '../LoadingSpinner';

const AuthRequiredRoute = (props: { component: React.ComponentType<any> }) => {
  const Component = withAuthenticationRequired(props.component, {
    onRedirecting: () => (
      <div className="page-layout">
        <LoadingSpinner />
      </div>
    ),
  });

  return <Component />;
};

export default AuthRequiredRoute;
