import React from 'react';

import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { Invoice, Subscription } from '../../../types/interfaces';
import ContributionsToDateKgCarbonRemoval from './ContributionsToDateKgCarbonRemoval';
import ContributionsToDateCurrencySpent from './ContributionsToDateCurrencySpent';
import ProjectedKgCarbonRemoval from './ProjectedKgCarbonRemoval';
import ProjectedCurrencySpent from './ProjectedCurrencySpent';

const DashboardStatCardSection = (props: {
  invoices: Invoice[];
  subscriptions: Subscription[];
  numberMonthsToProject: number[];
}) => {
  return (
    <SimpleGrid columns={[1, 2, null, null, 4]} mb={4} spacing={2}>
      <GridItem>
        <ContributionsToDateKgCarbonRemoval invoices={props.invoices} />
      </GridItem>
      <GridItem>
        <ContributionsToDateCurrencySpent invoices={props.invoices} />
      </GridItem>
      <GridItem>
        <ProjectedKgCarbonRemoval
          subscriptions={props.subscriptions}
          numberMonthsToProject={props.numberMonthsToProject}
        />
      </GridItem>
      <GridItem>
        <ProjectedCurrencySpent
          subscriptions={props.subscriptions}
          numberMonthsToProject={props.numberMonthsToProject}
        />
      </GridItem>
    </SimpleGrid>
  );
};

export default DashboardStatCardSection;
