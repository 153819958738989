import React, { ReactElement } from 'react';
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  HStack,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import Navbar from '../components/nav/Navbar';

const BasePage = (content: ReactElement) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <HStack minH="100vh" w="100vw" overflowX="hidden">
        <Box bg="#ffffff" w="12rem" display={{ base: 'none', lg: 'block' }}>
          <Navbar />
        </Box>

        <Box
          paddingY={8}
          paddingX={[8, 8, 12]}
          h="100vh"
          width="100vw"
          bg="#F8F9FA"
          overflowY="auto"
        >
          <IconButton
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            display={{ base: 'block', lg: 'none' }}
            onClick={onOpen}
            position="fixed"
            top="1rem"
            right="1rem"
            zIndex={1000}
          />
          {content}
        </Box>
      </HStack>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent maxW="180px" w="180px">
          <Navbar />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default BasePage;
