import {
  Box,
  Button,
  Heading,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

type EmptyStateBlurbProps = {
  heading?: string;
  message: string;
  link: { to: string; text: string };
};
const EmptyStateBlurb = ({ heading, message, link }: EmptyStateBlurbProps) => {
  return (
    <Box
      textAlign="center"
      backgroundColor="white"
      pb="2rem"
      pt="2rem"
      borderRadius="xl"
    >
      <Heading size="sm" pb="0.5rem">
        {heading}
      </Heading>
      <Text pb="0.5rem">{message}</Text>
      <ChakraLink as={ReactRouterLink} to={link.to}>
        <Button variant="solid" colorScheme="green" mb="2rem">
          {link.text}
        </Button>
      </ChakraLink>
    </Box>
  );
};

export default EmptyStateBlurb;
