export enum SolutionType {
  BIOCHAR_AND_BICRS = 'BIOCHAR_AND_BICRS',
  BIO_OIL = 'BIO_OIL',
  BIODIVERSITY_AND_AVOIDED_DEFORESTATION = 'BIODIVERSITY_AND_AVOIDED_DEFORESTATION',
  DIRECT_AIR_CAPTURE = 'DIRECT_AIR_CAPTURE',
  ENHANCED_ROCK_WEATHERING = 'ENHANCED_ROCK_WEATHERING',
  OCEAN_CARBON_REMOVAL = 'OCEAN_CARBON_REMOVAL',
  REFORESTATION_AND_AFFORESTATION = 'REFORESTATION_AND_AFFORESTATION',
  SOIL_CARBON_REMOVAL = 'SOIL_CARBON_REMOVAL',
}

export enum CurrencyType {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  CAD = 'cad',
  AUD = 'aud',
}

export enum SubscriptionStatus {
  Active = 'active',
  Paused = 'paused',
  Cancelled = 'cancelled',
}
