import { apiOrigin } from './constants';
import { callApi } from './utils';

export const stripeEndpoint = `${apiOrigin}/stripe/`;

export async function createCheckoutSession(
  accessToken: string,
  stripePriceId: string,
  mode: string,
  successUrl: string,
  cancelUrl: string,
) {
  const payload: Record<string, any> = {
    stripePriceId: stripePriceId,
    mode: mode,
    successUrl: successUrl,
    cancelUrl: cancelUrl,
  };
  return callApi(
    accessToken,
    `${stripeEndpoint}checkout-session/`,
    'POST',
    payload,
  );
}
