import {
  Box,
  Button,
  Heading,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

const EmptyStateBlurb = (props: {
  heading?: string;
  message: string;
  link: { to: string; text: string };
}) => {
  return (
    <Box
      textAlign="center"
      backgroundColor="white"
      pb="2rem"
      pt="2rem"
      borderRadius="xl"
    >
      <Heading size="sm" pb="0.5rem">
        {props.heading}
      </Heading>
      <Text pb="0.5rem">{props.message}</Text>
      <ChakraLink as={ReactRouterLink} to={props.link.to}>
        <Button variant="solid" colorScheme="green" mb="2rem">
          {props.link.text}
        </Button>
      </ChakraLink>
    </Box>
  );
};

export default EmptyStateBlurb;
