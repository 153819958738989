// API Functions
export async function callApi(
  accessToken: string,
  endpoint: string,
  method: string,
  data?: any,
) {
  const body = data ? JSON.stringify(data) : null;

  return await fetch(endpoint, {
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: body,
  });
}
