/* Solution Type / Partner objects */

export enum SolutionType {
  BIOCHAR_AND_BICRS = 'BIOCHAR_AND_BICRS',
  BIO_OIL = 'BIO_OIL',
  BIODIVERSITY_AND_AVOIDED_DEFORESTATION = 'BIODIVERSITY_AND_AVOIDED_DEFORESTATION',
  DIRECT_AIR_CAPTURE = 'DIRECT_AIR_CAPTURE',
  ENHANCED_ROCK_WEATHERING = 'ENHANCED_ROCK_WEATHERING',
  OCEAN_CARBON_REMOVAL = 'OCEAN_CARBON_REMOVAL',
  REFORESTATION_AND_AFFORESTATION = 'REFORESTATION_AND_AFFORESTATION',
  SOIL_CARBON_REMOVAL = 'SOIL_CARBON_REMOVAL',
}

export type SolutionTypeInfo = {
  color: string;
  name: string;
  permanenceOverFiveHundredYears: boolean;
  solutionTypeInformationLink: string;
};
export const SolutionTypeConfig: Record<SolutionType, SolutionTypeInfo> = {
  [SolutionType.BIOCHAR_AND_BICRS]: {
    color: '#F1E583',
    name: 'Biochar/BICRS',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/biochar-and-bicrs-biomass-removal-and-carbon-storage',
  },
  [SolutionType.BIO_OIL]: {
    color: '#9CB0A4',
    name: 'Bio Oil',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/bio-oil-and-permanent-carbon-storage',
  },
  [SolutionType.BIODIVERSITY_AND_AVOIDED_DEFORESTATION]: {
    color: '#F59677',
    name: 'Biodiversity and Avoided Deforestation',
    permanenceOverFiveHundredYears: false,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/nature-based-solutions-for-carbon-removal',
  },
  [SolutionType.DIRECT_AIR_CAPTURE]: {
    color: '#28C181',
    name: 'Direct Air Capture',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/direct-air-capture-and-storage',
  },
  [SolutionType.ENHANCED_ROCK_WEATHERING]: {
    color: '#80CE77',
    name: 'Enhanced Rock Weathering',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/enhanced-rock-weathering',
  },
  [SolutionType.OCEAN_CARBON_REMOVAL]: {
    color: '#E3EBDC',
    name: 'Ocean Carbon Removal',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/ocean-carbon-removal-and-storage',
  },
  [SolutionType.REFORESTATION_AND_AFFORESTATION]: {
    color: '#BCDB77',
    name: 'Reforestation and Afforestation',
    permanenceOverFiveHundredYears: false,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/nature-based-solutions-for-carbon-removal',
  },
  [SolutionType.SOIL_CARBON_REMOVAL]: {
    color: '#D39C73',
    name: 'Soil Carbon Removal',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/soil-carbon-removal',
  },
};

export interface SolutionTypeData {
  id: SolutionType;
  color: string;
  name: string;
  description: string;
  shortDescription: string;
  permanence: string;
  permanenceOverFiveHundredYears: boolean;
  additionality: string;
  scalingPotential: string;
  coBenefits: string;
  verifiability: string;
  solutionTypeInformationLink: string;
  staticImageLink: string;
}

export interface PortfolioPartnerData {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
  solutionType: SolutionType;
  location: string;
  companyStage: string;
  permanence: string;
  additionality: string;
  partnerInformationLink: string;
  staticImageLink: string;
  isActive: boolean;
}

export enum CurrencyType {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  CAD = 'cad',
  AUD = 'aud',
}

/* API Object Types */
export interface Portfolio {
  id: number;
  name: string;
}

export interface Invoice {
  id: number;
  name: string;
  purchaseDate: string;
  kgCarbonRemoval: number;
  currencyAmount: number;
  currencyType: CurrencyType;
  portfolio: Portfolio;
}

export type InvoiceContribution = {
  id: number;
  createdAt: string;
  lastUpdatedAt: string | null;
  userId: number;
  invoiceId: number;
  solutionType: SolutionType;
  kgCarbonRemoval: number;
  invoice: { purchaseDate: string };
};

export enum SubscriptionStatus {
  Active = 'active',
  Paused = 'paused',
  Cancelled = 'cancelled',
}

export interface Subscription {
  name: string;
  status: SubscriptionStatus;
  stripeSubscriptionId: string;
  stripeCustomerId: string;
  startDate: string;
  nextPaymentDate: string;
  currencyAmount: number;
  currencyType: CurrencyType;
  kgCarbonRemoval: number;
  portfolioName: string;
  cancelAtPeriodEnd: boolean;
}
export interface PaymentLink {
  stripeId: string;
  stripePriceId: string;
  isSubscription: boolean;
  description: string;
  currencyType: CurrencyType;
  currencyAmount: number;
  kgCarbonRemoval: number;
}

/* Misc Types */

export interface SolutionTypeAggregation {
  type: SolutionType;
  totalVolume: number;
  percentage: number;
}
export interface CarbonEquivalenceMilestone {
  kgCarbonRemoval: number;
  text: string;
  imageLink: string;
}
export interface CourseData {
  id: number;
  name: string;
  preface: string;
  description: string;
  informationLink: string;
  color: string;
  textColor: string;
}
export interface DictionaryTerm {
  definition: string;
  moreInfoLink: string | null;
}
export interface DictionaryData {
  [letter: string]: {
    [term: string]: DictionaryTerm;
  };
}
