import React, { useEffect, useState } from 'react';
import { Box, Heading, Link, Stack, Text } from '@chakra-ui/react';

import BackButton from '../../components/buttons/BackButton';
import LoadingWrapper from '../../components/LoadingWrapper';
import BasePage from '../../components/BasePage';
import { DictionaryData } from '../../types/interfaces';

type DictionaryPageState = {
  dictionaryData: DictionaryData | null;
  isLoading: boolean;
};

const DictionaryPage = () => {
  const [state, setState] = useState<DictionaryPageState>({
    dictionaryData: null,
    isLoading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dictionaryData = await fetch('/data/dictionaryData.json').then(
          (response) => response.json(),
        );
        setState((prevState) => ({
          ...prevState,
          dictionaryData,
          isLoading: false,
        }));
      } catch (error) {
        console.error(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };

    fetchData();
  }, [setState]);

  const renderDictionaryTerms = () => {
    if (!state.dictionaryData) {
      return null;
    }

    return (
      <Stack spacing={4} paddingX={12}>
        {Object.entries(state.dictionaryData).map(([letter, terms]) => (
          <Stack key={letter} spacing={2}>
            <Heading as="h2" size="md">
              {letter}
            </Heading>
            {Object.entries(terms).map(
              ([term, { definition, moreInfoLink }]) => (
                <Box key={term}>
                  <Text>
                    {moreInfoLink ? (
                      <Link href={moreInfoLink} color="teal.500" isExternal>
                        {term}
                      </Link>
                    ) : (
                      <em>{term}</em>
                    )}
                    &nbsp;- {definition}
                  </Text>
                </Box>
              ),
            )}
          </Stack>
        ))}
      </Stack>
    );
  };

  return (
    <BasePage>
      <Stack spacing={16} p={4}>
        <BackButton text="Back to Learning Hub" url="/learning" />
        <Stack spacing={4}>
          <Heading as="h1" size="lg">
            Climate Buzzword Dictionary
          </Heading>
          <Text fontSize="md">
            This is a summary of all the climate related terminology, buzzwords
            and acronymns used in our Intro to Climate, Code Red for Humanity,
            Climate Companies and Carbon Removal email courses.
          </Text>
        </Stack>
        <LoadingWrapper isLoading={state.isLoading}>
          {renderDictionaryTerms()}
        </LoadingWrapper>
      </Stack>
    </BasePage>
  );
};

export default DictionaryPage;
