import React from 'react';
import { Box, Center, Heading, Spinner } from '@chakra-ui/react';

const LoadingSpinner = () => {
  return (
    <>
      <Box w="100%" p="1rem">
        <Center>
          <Heading as="h4" size="sm">
            Loading...
          </Heading>
        </Center>
      </Box>
      <Box w="100%">
        <Center>
          <Spinner size="lg" color="teal.500" />
        </Center>
      </Box>
    </>
  );
};

export default LoadingSpinner;
