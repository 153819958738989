import React from 'react';
import { Auth0ContextInterface, withAuth0 } from '@auth0/auth0-react';
import { Button } from '@chakra-ui/react';

const LogoutButton = (props: { auth0: Auth0ContextInterface }) => {
  const { isAuthenticated, isLoading, logout } = props.auth0;

  return (
    isAuthenticated &&
    !isLoading && (
      <Button
        colorScheme="green"
        onClick={() =>
          logout({ logoutParams: { returnTo: 'https://zopeful.com/' } })
        }
      >
        Log out
      </Button>
    )
  );
};

export default withAuth0(LogoutButton);
