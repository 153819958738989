import React, { useMemo, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Circle,
  Text,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

import { SolutionTypeConfig, SolutionType } from '../../types';
import CurrencyNumber from '../../utils/CurrencyNumber';

export interface Column {
  header: string;
  accessor: string;
}

export interface SortConfig {
  key: string;
  direction: 'ascending' | 'descending';
}

interface SortableTableProps {
  data: any[];
  columns: Column[];
  startingSortConfig?: SortConfig;
  actionsColumn?: Column;
}

const SortableTable: React.FC<SortableTableProps> = ({
  data,
  columns,
  startingSortConfig,
  actionsColumn,
}) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(
    startingSortConfig === undefined ? null : startingSortConfig,
  );

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        const aVal = a[sortConfig.key];
        const bVal = b[sortConfig.key];

        // TODO: Not a great solution for handling CurrencyNumbers, so we may want to improve this later
        if (aVal instanceof CurrencyNumber) {
          return sortConfig.direction === 'ascending'
            ? aVal.compare(bVal)
            : bVal.compare(aVal);
        }

        if (aVal < bVal) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aVal > bVal) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const requestSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderCell = (row: any, column: Column) => {
    let cellValue = row[column.accessor];
    if (cellValue instanceof CurrencyNumber) {
      cellValue = cellValue.toString();
    } else if (cellValue instanceof Date) {
      cellValue = cellValue.toLocaleDateString();
    }

    if (column.accessor !== 'solutionType') {
      // TODO: Not a great solution for handling CurrencyNumbers,
      // so we may want to improve this later
      return <Td key={column.accessor}>{cellValue}</Td>;
    } else {
      const solutionType: SolutionType = cellValue;
      return (
        <Td key={column.accessor}>
          <HStack spacing="2">
            <Circle size="8px" bg={SolutionTypeConfig[solutionType].color} />
            <Text>{SolutionTypeConfig[solutionType].name}</Text>
          </HStack>
        </Td>
      );
    }
  };

  return (
    <Box overflowX="auto" borderRadius="xl">
      <Table size="sm">
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th
                key={column.accessor}
                onClick={() => requestSort(column.accessor)}
              >
                {column.header}&nbsp;&nbsp;
                {sortConfig && sortConfig.key === column.accessor ? (
                  sortConfig.direction === 'ascending' ? (
                    <TriangleUpIcon boxSize={3} />
                  ) : (
                    <TriangleDownIcon boxSize={3} />
                  )
                ) : null}
              </Th>
            ))}
            {actionsColumn && <Th>{actionsColumn.header}</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {sortedData.map((row, index) => {
            return (
              <Tr key={index}>
                {columns.map((column) => {
                  return renderCell(row, column);
                })}
                {actionsColumn && <Td>{row[actionsColumn.accessor]}</Td>}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default SortableTable;
