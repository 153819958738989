import React from 'react';

import {
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';

import { CourseData } from '../../types/interfaces';

const CourseCard = (props: { courseData: CourseData }) => {
  return (
    <Card height="100%" borderRadius="xl">
      <Center
        borderTopRadius="xl"
        color={props.courseData.textColor}
        bgColor={props.courseData.color}
        height="300px"
        padding={4}
        textAlign="center"
      >
        <Heading as="h2" size="lg">
          {props.courseData.name}
        </Heading>
      </Center>
      <CardBody p={4}>
        <Flex direction="column" height="100%">
          <Stack spacing={2} flex="1">
            <Heading as="h5" size="xs">
              {props.courseData.preface.toUpperCase()}
            </Heading>
            <Text>{props.courseData.description}</Text>
          </Stack>
          <Link
            href={props.courseData.informationLink}
            isExternal
            textAlign="right"
            mb={3}
            mr={4}
          >
            <Button colorScheme="green">Register now</Button>
          </Link>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default CourseCard;
