import { ReactNode } from 'react';
import LoadingSpinner from './LoadingSpinner';

const LoadingWrapper = (props: { isLoading: boolean; children: ReactNode }) => {
  if (props.isLoading) {
    return <LoadingSpinner />;
  }
  return props.children;
};

export default LoadingWrapper;
