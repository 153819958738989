import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  GridItem,
  Link as ChakraLink,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Auth0ContextInterface, withAuth0 } from '@auth0/auth0-react';

import {
  getInvoices,
  getInvoiceContributions,
  getSubscriptions,
} from '../../api';
import EmptyStateBlurb from '../../components/EmptyStateBlurb';
import LoadingSpinner from '../../components/LoadingSpinner';
import SortableTable, { Column } from '../../components/tables/SortableTable';
import {
  CarbonEquivalenceMilestone,
  Invoice,
  InvoiceContribution,
  PortfolioPartnerData,
  SolutionTypeAggregation,
  Subscription,
} from '../../types';
import { getSolutionTypeAggregations } from '../../utils/utils';
import BasePage from '../BasePage';
import DashboardContributionCardSection from './DashboardContributionCardSection';
import DashboardBlurbSection from './DashboardBlurbSection';
import DashboardSolutionTypes from './DashboardSolutionTypes';
import DashboardContributionChart from './DashboardContributionsChart';

type DashboardState = {
  equivalencesData: {
    milestones: CarbonEquivalenceMilestone[];
    isLoading: boolean;
  };
  invoiceContributionData: {
    data: InvoiceContribution[];
    solutionTypeAggegations: SolutionTypeAggregation[];
    isLoading: boolean;
  };
  invoiceData: {
    data: Invoice[];
    totalKGCarbonRemoved: number;
    isLoading: boolean;
  };
  portfolioPartnersData: {
    data: Invoice[];
    isLoading: boolean;
  };
  subscriptionData: {
    data: Subscription[];
    isLoading: boolean;
  };
};
interface DashboardProps {
  auth0: Auth0ContextInterface;
}
const partnerTableColumns: Column[] = [
  { header: 'Partner', accessor: 'partner' },
  { header: 'Solution Type', accessor: 'solutionType' },
  { header: 'Company Stage', accessor: 'companyStage' },
  { header: 'Location', accessor: 'location' },
];

function Dashboard({ auth0 }: DashboardProps) {
  const [state, setState] = useState<DashboardState>({
    equivalencesData: {
      milestones: [],
      isLoading: true,
    },
    invoiceContributionData: {
      data: [],
      solutionTypeAggegations: [],
      isLoading: true,
    },
    invoiceData: {
      data: [],
      totalKGCarbonRemoved: 0,
      isLoading: true,
    },
    portfolioPartnersData: {
      data: [],
      isLoading: true,
    },
    subscriptionData: {
      data: [],
      isLoading: true,
    },
  });

  useEffect(() => {
    fetch('/data/equivalences.json')
      .then((response) => response.json())
      .then((jsonData) =>
        setState((prevState) => {
          return {
            ...prevState,
            equivalencesData: {
              equivalences: jsonData.equivalences,
              milestones: jsonData.milestones,
              isLoading: false,
            },
          };
        }),
      )
      .catch((error) => {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          equivalencesData: { ...prevState.equivalencesData, isLoading: false },
        }));
      });
  }, [setState]);

  useEffect(() => {
    const preprocessPartnersInPortfolio = (partner: PortfolioPartnerData) => {
      let partnerLink = (
        <ChakraLink
          color="teal.500"
          as={ReactRouterLink}
          to={`/partner/${partner.id}`}
        >
          {partner.name}
        </ChakraLink>
      );
      return {
        partner: partnerLink,
        solutionType: partner.solutionType,
        companyStage: partner.companyStage,
        location: partner.location,
      };
    };

    setState((prevState) => ({
      ...prevState,
      portfolioPartnersData: {
        ...prevState.portfolioPartnersData,
        isLoading: true,
      },
    }));
    fetch('/data/partnerData.json')
      .then((response) => response.json())
      .then((jsonData) =>
        setState((prevState) => ({
          ...prevState,
          portfolioPartnersData: {
            data: jsonData.partnersInPortfolio.map(
              preprocessPartnersInPortfolio,
            ),
            isLoading: false,
          },
        })),
      )
      .catch((error) => {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          portfolioPartnersData: {
            ...prevState.portfolioPartnersData,
            isLoading: false,
          },
        }));
      });
  }, [setState]);

  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({
        ...prevState,
        invoiceContributionData: {
          ...prevState.invoiceContributionData,
          isLoading: true,
        },
      }));
      try {
        const token = await auth0.getAccessTokenSilently();

        const response = await getInvoiceContributions(token);

        const data = await response.json();
        const solutionTypeAggregation: SolutionTypeAggregation[] =
          getSolutionTypeAggregations(data);

        if (response.ok) {
          setState((prevState) => ({
            ...prevState,
            invoiceContributionData: {
              data: data,
              solutionTypeAggegations: solutionTypeAggregation,
              isLoading: false,
            },
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          invoiceContributionData: {
            ...prevState.invoiceContributionData,
            isLoading: false,
          },
        }));
      }
    };

    fetchData();
  }, [auth0, setState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await auth0.getAccessTokenSilently();

        const response = await getInvoices(token);

        const data = await response.json();

        if (response.ok) {
          const totalKGCarbonRemoved = data.reduce(
            (acc: number, { kgCarbonRemoval }: Invoice) =>
              acc + kgCarbonRemoval,
            0,
          );
          setState((prevState) => ({
            ...prevState,
            invoiceData: {
              data: data,
              totalKGCarbonRemoved: totalKGCarbonRemoved,
              isLoading: false,
            },
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          invoiceData: { ...prevState.invoiceData, isLoading: false },
        }));
      }
    };

    fetchData();
  }, [auth0, setState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await auth0.getAccessTokenSilently();

        const response = await getSubscriptions(token);

        const data = await response.json();

        if (response.ok) {
          setState((prevState) => ({
            ...prevState,
            subscriptionData: { data: data, isLoading: false },
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          subscriptionData: { ...prevState.subscriptionData, isLoading: false },
        }));
      }
    };

    fetchData();
  }, [auth0, setState]);

  const renderDashboardBlurbSection = () => {
    if (state.equivalencesData.isLoading || state.invoiceData.isLoading) {
      return <LoadingSpinner />;
    }
    return (
      <DashboardBlurbSection
        milestones={state.equivalencesData.milestones}
        kgCarbonRemoved={state.invoiceData.totalKGCarbonRemoved}
      />
    );
  };
  const renderDashboardContributionChart = () => {
    if (state.invoiceData.isLoading) {
      return <LoadingSpinner />;
    }
    return (
      state.invoiceData.data.length > 0 && (
        <Box paddingY={4} paddingX={2} bg="white" borderRadius="xl">
          <Heading size="md" as="h2" pl={2}>
            Your contributions over time
          </Heading>
          <DashboardContributionChart data={state.invoiceData.data} />
        </Box>
      )
    );
  };
  const renderSolutionTypeTable = () => {
    if (state.invoiceContributionData.isLoading) {
      return <LoadingSpinner />;
    }
    return (
      state.invoiceContributionData.solutionTypeAggegations.length > 0 && (
        <DashboardSolutionTypes
          data={state.invoiceContributionData.solutionTypeAggegations}
        />
      )
    );
  };
  const renderPortfolioPartnersTable = () => {
    if (state.portfolioPartnersData.isLoading) {
      return <LoadingSpinner />;
    }
    return (
      state.portfolioPartnersData.data && (
        <SortableTable
          data={state.portfolioPartnersData.data}
          columns={partnerTableColumns}
        />
      )
    );
  };

  const renderEmptyState = () => {
    return (
      <Box>
        <Box m={4} bg="white" borderRadius="xl">
          <EmptyStateBlurb
            heading="Make your first contribution to carbon removal!"
            message="Join with a monthly subscription or a one-time purchase. Every tonne matters."
            link={{ to: '/purchase', text: 'Start your portfolio today' }}
          />
        </Box>
        <Box p={4} m={4} bg="white" borderRadius="xl">
          <Heading size="md" as="h2" pb={2}>
            Suppliers in Portfolio-1
          </Heading>
          {renderPortfolioPartnersTable()}
        </Box>
      </Box>
    );
  };

  const renderData = () => {
    return (
      <Box display="flex" flexDirection="column" boxSizing="border-box">
        <SimpleGrid columns={[1, null, 2]} spacing={8} mb={4}>
          {renderDashboardBlurbSection()}
          {renderDashboardContributionChart()}
        </SimpleGrid>
        <SimpleGrid columns={[1, 2, null, 4]} mb={4} spacing={2}>
          {!state.invoiceData.isLoading &&
            !state.subscriptionData.isLoading && (
              <DashboardContributionCardSection
                invoices={state.invoiceData.data}
                subscriptions={state.subscriptionData.data}
              />
            )}
        </SimpleGrid>

        <SimpleGrid columns={[1, null, null, 10]} spacing={4} mb={4}>
          <GridItem
            colSpan={[1, 1, 1, 4]}
            h="100%"
            p={4}
            bg="white"
            borderRadius="xl"
          >
            <Heading size="md" as="h2">
              Portfolio by solution type
            </Heading>
            {renderSolutionTypeTable()}
          </GridItem>
          <GridItem colSpan={[1, 1, 1, 6]} h="100%">
            <Box p={4} bg="white" borderRadius="xl">
              <Heading size="md" as="h2">
                Suppliers in Portfolio-1
              </Heading>
              {renderPortfolioPartnersTable()}
            </Box>
          </GridItem>
        </SimpleGrid>
      </Box>
    );
  };

  return BasePage(
    <Stack spacing={8}>
      <Heading as="h2" size="lg">
        Dashboard
      </Heading>
      {!state.invoiceContributionData.isLoading &&
      state.invoiceContributionData.data.length === 0
        ? renderEmptyState()
        : renderData()}
    </Stack>,
  );
}

export default withAuth0(Dashboard);
