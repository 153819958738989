import { InvoiceContribution, SolutionType } from '../types';

const calculateTotalVolume = (invoiceContributions: InvoiceContribution[]) =>
  invoiceContributions.reduce(
    (total, item) =>
      Math.round(total + (item.solutionType ? item.kgCarbonRemoval : 0)),
    0,
  );

const getSolutionTypeToInvoiceContributions = (
  invoiceContributions: InvoiceContribution[],
) =>
  invoiceContributions.reduce(
    (
      acc: Record<
        string,
        { type: SolutionType; totalVolume: number; percentage: number }
      >,
      item: InvoiceContribution,
    ) => {
      if (item.solutionType) {
        if (!acc[item.solutionType]) {
          acc[item.solutionType] = {
            type: item.solutionType,
            totalVolume: 0,
            percentage: 0,
          };
        }
        acc[item.solutionType].totalVolume = Math.round(
          acc[item.solutionType].totalVolume + item.kgCarbonRemoval,
        );
      }
      return acc;
    },
    {} as Record<
      string,
      { type: SolutionType; totalVolume: number; percentage: number }
    >,
  );

const calculatePercentages = (
  aggObj: Record<string, { totalVolume: number; percentage: number }>,
  total: number,
) => {
  Object.values(aggObj).forEach((sumObj) => {
    sumObj.percentage = Math.floor((sumObj.totalVolume / total) * 100);
  });
};

export const getSolutionTypeAggregations = (
  invoiceContributions: InvoiceContribution[],
) => {
  const total = calculateTotalVolume(invoiceContributions);
  let solutionTypeToInvoiceContributions =
    getSolutionTypeToInvoiceContributions(invoiceContributions);
  calculatePercentages(solutionTypeToInvoiceContributions, total);
  return Object.values(solutionTypeToInvoiceContributions).sort(
    (a, b) => b.totalVolume - a.totalVolume,
  );
};

export const generateRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  return `rgb(${red}, ${green}, ${blue})`;
};
