import React from 'react';
import {
  Circle,
  HStack,
  Flex,
  Box,
  Link as ChakraLink,
  Table,
  Tag,
  Text,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { VictoryPie, VictoryLabel } from 'victory';

import { SolutionTypeConfig } from '../../types/types';
import { SolutionTypeAggregation } from '../../types/interfaces';

const DashboardSolutionTypes = (props: { data: SolutionTypeAggregation[] }) => {
  return (
    <Box borderRadius="xl">
      <Flex
        justifyContent="center"
        h="200px"
        mt="4"
        w="100%"
        alignItems="center"
      >
        <Box w="250px">
          <svg viewBox="0 0 300 300">
            <VictoryPie
              standalone={false}
              innerRadius={100}
              radius={120}
              data={props.data.map((d) => ({
                x: d.type,
                y: d.totalVolume,
                color: SolutionTypeConfig[d.type].color,
              }))}
              style={{
                data: {
                  fill: ({ datum }) => datum.color,
                },
                labels: {
                  display: 'none',
                },
              }}
              origin={{ x: 150, y: 150 }}
            />
            <circle
              cx="150"
              cy="150"
              r="100"
              fill="white"
              stroke="#E3EBDC"
              strokeWidth="10"
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 50 }}
              x={150}
              y={130}
              text="55%"
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 16 }}
              x={150}
              y={160}
              text="permanence over 500 years"
            />
          </svg>
        </Box>
      </Flex>
      <Box mt="8">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>Solution Type</Th>
              <Th isNumeric>Volume (kg)</Th>
              <Th isNumeric>Total (%)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.data.map((item) => {
              const config = SolutionTypeConfig[item.type];
              const color = config.color;
              const name = config.name;
              return (
                <Tr key={item.type}>
                  <Td>
                    <HStack spacing="2">
                      <Circle size="8px" bg={color} />
                      <Text>
                        <ChakraLink
                          color="teal.500"
                          as={ReactRouterLink}
                          to={`/solution-type/${item.type}`}
                        >
                          {name}
                        </ChakraLink>
                      </Text>
                    </HStack>
                  </Td>
                  <Td>{item.totalVolume.toLocaleString()}</Td>
                  <Td>
                    <Tag size="sm" borderRadius="full" bg={color} color="black">
                      {item.percentage}%
                    </Tag>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default DashboardSolutionTypes;
