import React from 'react';
import {
  Box,
  Card,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

interface CardTab {
  listTitle: string;
  statNumber: string;
  isIncrease: boolean;
  changeAmountString?: string;
}

const DashboardStatCard = (props: { label: string; tabs: CardTab[] }) => {
  return (
    <Card pt={3} paddingX={2} w={220} h={175} borderRadius="xl">
      <Box>
        <StatGroup>
          <Stat>
            <Box w="150px">
              <StatLabel fontSize="sm" pb={2}>
                {props.label}
              </StatLabel>
            </Box>
            <Tabs size="sm" variant="soft-rounded" colorScheme="green">
              <Box w={200}>
                <TabList overflowX="scroll">
                  {props.tabs.map((tab: CardTab) => (
                    <Tab paddingX={3} key={tab.listTitle}>
                      {tab.listTitle}
                    </Tab>
                  ))}
                </TabList>
              </Box>
              <TabPanels>
                {props.tabs.map((tab: CardTab) => (
                  <TabPanel key={tab.listTitle}>
                    <StatNumber fontSize="lg">{tab.statNumber}</StatNumber>
                    {tab.changeAmountString && (
                      <StatHelpText fontSize="xs">
                        <StatArrow
                          type={tab.isIncrease ? 'increase' : 'decrease'}
                        />
                        {tab.changeAmountString}
                      </StatHelpText>
                    )}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Stat>
        </StatGroup>
      </Box>
    </Card>
  );
};

export default DashboardStatCard;
