import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import PageLoader from '../pages/PageLoader';

const AuthRequiredRoute: React.FC<{ component: React.ComponentType<any> }> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};

export default AuthRequiredRoute;
