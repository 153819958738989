// Endpoints
import { apiOrigin } from './constants';
import { callApi } from './utils';

export const invoicesEndpoint = `${apiOrigin}/invoices/`;
export const invoiceContributionsEndpoint = `${apiOrigin}/invoice-contributions/`;

export async function getInvoices(accessToken: string) {
  return callApi(accessToken, `${invoicesEndpoint}me/`, 'GET');
}

export async function getInvoiceContributions(accessToken: string) {
  return callApi(accessToken, `${invoiceContributionsEndpoint}me/`, 'GET');
}
