import { Route, Routes } from 'react-router-dom';
import AuthRequiredRoute from './components/nav/AuthRequiredRoute';

import { useAuth0 } from '@auth0/auth0-react';

import LoadingWrapper from './components/LoadingWrapper';
import ContributionsPage from './pages/contributions/ContributionsPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import DictionaryPage from './pages/dictionary/DictionaryPage';
import LearningPage from './pages/learning/LearningPage';
import PurchasePage from './pages/purchase/PurchasePage';
import PartnerPage from './pages/partners/PartnerPage';
import SolutionTypePage from './pages/solution-types/SolutionTypePage';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  const { isLoading } = useAuth0();

  return (
    <LoadingWrapper isLoading={isLoading}>
      <ScrollToTop />
      <Routes>
        <Route
          path="/dashboard"
          element={<AuthRequiredRoute component={DashboardPage} />}
        />
        <Route
          path="/contributions"
          element={<AuthRequiredRoute component={ContributionsPage} />}
        />
        <Route
          path="/dictionary"
          element={<AuthRequiredRoute component={DictionaryPage} />}
        />
        <Route
          path="/learning"
          element={<AuthRequiredRoute component={LearningPage} />}
        />
        <Route
          path="/partner/:partnerId"
          element={<AuthRequiredRoute component={PartnerPage} />}
        />
        <Route
          path="/solution-type/:solutionTypeId"
          element={<AuthRequiredRoute component={SolutionTypePage} />}
        />
        <Route
          path="/purchase"
          element={<AuthRequiredRoute component={PurchasePage} />}
        />
        <Route
          path="/"
          element={<AuthRequiredRoute component={DashboardPage} />}
        />
      </Routes>
    </LoadingWrapper>
  );
};

export default App;
