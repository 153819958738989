import { Link as ChakraLink } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const BackButton = (props: { text: string; url?: string }) => {
  const navigate = useNavigate();

  const backIcon = <ChevronLeftIcon boxSize={7} />;

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (props.url) {
      navigate(props.url);
    } else {
      navigate(-1); // Navigate to the last page visited
    }
  };

  return (
    <ChakraLink as={RouterLink} onClick={handleClick}>
      {backIcon}
      {props.text}
    </ChakraLink>
  );
};

export default BackButton;
