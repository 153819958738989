import React from 'react';

import { Box, Center, SimpleGrid } from '@chakra-ui/react';

import { PaymentLink } from '../../types';
import PurchaseCard from './PurchaseCard';

interface PurchaseCardsSectionProps {
  paymentLinks: PaymentLink[];
}

const PurchaseCardsSection = ({ paymentLinks }: PurchaseCardsSectionProps) => {
  const CARD_WIDTH = 325;
  const CARD_HEIGHT = 425;
  const COL_SPACING = 20;
  const NUM_COLUMNS = [1, null, 2, null, 3, 4];
  const GRID_WIDTHS = NUM_COLUMNS.map((cols) => {
    if (cols === null) return null;
    return CARD_WIDTH * cols + COL_SPACING * (cols - 1);
  });

  return (
    <Center>
      <SimpleGrid
        columns={NUM_COLUMNS}
        w={GRID_WIDTHS}
        justifyContent="center"
        spacingX={COL_SPACING}
        spacingY="40px"
      >
        {paymentLinks.map((link) => (
          <Box w={CARD_WIDTH} h={CARD_HEIGHT} key={link.stripePriceId}>
            <PurchaseCard paymentLink={link} />
          </Box>
        ))}
      </SimpleGrid>
    </Center>
  );
};

export default PurchaseCardsSection;
