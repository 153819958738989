import React, { ReactElement } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Box, Flex, Link as ChakraLink, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/icons';

const NavbarItem = (
  to: string,
  text: string,
  icon: ReactElement<typeof Icon>,
  isExternal: boolean = false,
) => {
  return (
    <Box w="100%" textAlign="left">
      {isExternal ? (
        <ChakraLink href={to} isExternal={true}>
          <Flex alignItems="center">
            {icon}
            <Text ml={2}>
              {text}
              <sup>
                <ExternalLinkIcon />
              </sup>
            </Text>
          </Flex>
        </ChakraLink>
      ) : (
        <ChakraLink as={ReactRouterLink} to={to}>
          <Flex alignItems="center">
            {icon}
            <Text ml={2}>{text}</Text>
          </Flex>
        </ChakraLink>
      )}
    </Box>
  );
};

export default NavbarItem;
