import React from 'react';

const PageLoader = () => {
  return (
    <div>
      <h2>Loading...</h2>
    </div>
  );
};

export default PageLoader;
