import { CurrencyType } from '../types';

export default class CurrencyNumber {
  currencyAmount: number;
  currencyType: CurrencyType;
  formatter: Intl.NumberFormat;

  constructor(
    currencyAmount: number,
    currencyType: CurrencyType,
    shouldNormalize: boolean = false,
  ) {
    this.currencyAmount = shouldNormalize
      ? normalizeCurrencyNumber(currencyAmount, currencyType)
      : currencyAmount;
    this.currencyType = currencyType;
    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyType.toString().toUpperCase(),
      maximumFractionDigits: 2,
    });
  }

  toString() {
    return this.formatter.format(this.currencyAmount);
  }

  compare(currencyNumber: CurrencyNumber) {
    return this.currencyAmount - currencyNumber.currencyAmount;
  }
}

// Stripe returns currency amounts in their lowest possible unit (e.g. cents for USD).
//  This function normalizes the currency amount to the standard currency unit (e.g. dollars for USD).
export const normalizeCurrencyNumber = (
  currencyAmount: number,
  currencyType: CurrencyType,
) => {
  switch (currencyType) {
    case CurrencyType.USD:
    case CurrencyType.CAD:
    case CurrencyType.AUD:
    case CurrencyType.EUR:
    case CurrencyType.GBP:
      return currencyAmount / 100;
    default:
      return currencyAmount;
  }
};
