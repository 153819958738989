import React from 'react';
import {
  Card,
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
} from '@chakra-ui/react';

export type CardTab = {
  listTitle: string;
  statNumber: string;
  isIncrease: boolean;
  changeAmountString?: string;
};

export interface CardData {
  label: string;
  tabs: CardTab[];
}

const DashboardContributionCard = ({ label, tabs }: CardData) => {
  return (
    <Card pt={3} paddingX={3} w={250} h={175} borderRadius="xl">
      <Box>
        <StatGroup>
          <Stat>
            <Box w="150px">
              <StatLabel fontSize="sm" pb={2}>
                {label}
              </StatLabel>
            </Box>
            <Tabs size="sm" variant="soft-rounded" colorScheme="green">
              <TabList>
                {tabs.map((tab: CardTab) => (
                  <Tab paddingX={3} key={tab.listTitle}>
                    {tab.listTitle}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {tabs.map((tab: CardTab) => (
                  <TabPanel key={tab.listTitle}>
                    <StatNumber fontSize="lg">{tab.statNumber}</StatNumber>
                    {tab.changeAmountString && (
                      <StatHelpText fontSize="xs">
                        <StatArrow
                          type={tab.isIncrease ? 'increase' : 'decrease'}
                        />
                        {tab.changeAmountString}
                      </StatHelpText>
                    )}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Stat>
        </StatGroup>
      </Box>
    </Card>
  );
};

export default DashboardContributionCard;
