import React, { useState, useEffect } from 'react';

import { Box, Heading } from '@chakra-ui/react';
import { Auth0ContextInterface, withAuth0 } from '@auth0/auth0-react';

import { getSubscriptions } from '../../api';
import EmptyStateBlurb from '../../components/EmptyStateBlurb';
import LoadingSpinner from '../../components/LoadingSpinner';
import SortableTable, { Column } from '../../components/tables/SortableTable';
import { Subscription, SubscriptionStatus } from '../../types';
import CurrencyNumber from '../../utils/CurrencyNumber';
import SubscriptionTableRowPopover from './SubscriptionTableRowPopover';

interface SubscriptionsTableProps {
  auth0: Auth0ContextInterface;
}
interface SubscriptionsTableState {
  loading: boolean;
  subscriptions: Subscription[];
  error: string | null;
}

const columns: Column[] = [
  { header: 'Description', accessor: 'description' },
  { header: 'Status', accessor: 'status' },
  { header: 'Start Date', accessor: 'startDate' },
  { header: 'Next Payment', accessor: 'nextPaymentDate' },
  { header: 'Total Amount', accessor: 'currencyAmount' },
];
const actionsColumn: Column = { header: 'Actions', accessor: 'actions' };

function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const SubscriptionsTable = ({ auth0 }: SubscriptionsTableProps) => {
  const [state, setState] = useState<SubscriptionsTableState>({
    loading: true,
    subscriptions: [],
    error: null,
  });

  useEffect(() => {
    const getSubscriptionRowDataFromSubscription = (
      subscription: Subscription,
    ) => {
      let statusMessage = capitalizeFirstLetter(subscription.status.toString());
      if (
        subscription.status === SubscriptionStatus.Active &&
        subscription.cancelAtPeriodEnd
      ) {
        statusMessage = `Active until ${new Date(
          subscription.nextPaymentDate,
        ).toLocaleDateString()}`;
      }
      return {
        description: subscription.portfolioName,
        status: statusMessage,
        startDate: new Date(subscription.startDate),
        nextPaymentDate: !subscription.cancelAtPeriodEnd
          ? new Date(subscription.nextPaymentDate)
          : null,
        currencyAmount: new CurrencyNumber(
          subscription.currencyAmount,
          subscription.currencyType,
          true,
        ),
        actions: (
          <SubscriptionTableRowPopover
            stripeSubscriptionId={subscription.stripeSubscriptionId}
            stripeSubscriptionCurrency={subscription.currencyType}
            stripeCustomerId={subscription.stripeCustomerId}
            cancelAtPeriodEnd={subscription.cancelAtPeriodEnd}
          />
        ),
      };
    };
    const fetchData = async () => {
      try {
        const token = await auth0.getAccessTokenSilently();

        const response = await getSubscriptions(token);

        const responseData = await response.json();

        if (!response.ok) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            subscriptions: [],
            error: responseData.error,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            subscriptions: responseData.map(
              getSubscriptionRowDataFromSubscription,
            ),
          }));
        }
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          subscriptions: [],
          error: error.error,
        }));
      }
    };

    fetchData();
  }, [auth0, setState]);

  const renderTable = () => {
    return (
      <>
        {state.subscriptions.length !== 0 ? (
          <SortableTable
            data={state.subscriptions}
            columns={columns}
            startingSortConfig={{ key: 'startDate', direction: 'descending' }}
            actionsColumn={actionsColumn}
          />
        ) : (
          <EmptyStateBlurb
            heading="Subscribe for monthly carbon removal!"
            message="Join Zopeful by starting a monthly subscription. Every tonne matters."
            link={{ to: '/purchase', text: 'Subscribe' }}
          />
        )}
      </>
    );
  };

  return (
    <Box width="100%">
      <Heading as="h2" pb="2vh" size="md">
        Monthly subscriptions
      </Heading>
      {state.loading ? <LoadingSpinner /> : renderTable()}
    </Box>
  );
};

export default withAuth0(SubscriptionsTable);
