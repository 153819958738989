import { apiOrigin } from './constants';
import { callApi } from './utils';

export const subscriptionsEndpoint = `${apiOrigin}/subscriptions/`;

export async function getSubscriptions(accessToken: string) {
  return callApi(accessToken, `${subscriptionsEndpoint}me/`, 'GET');
}

export async function createSessionToUpdateSubscription(
  accessToken: string,
  stripeSubscriptionId: string,
  stripeCustomerId: string,
  returnUrl: string,
) {
  return callApi(
    accessToken,
    `${subscriptionsEndpoint}stripe/${stripeSubscriptionId}`,
    'PUT',
    { stripeCustomerId: stripeCustomerId, returnUrl: returnUrl },
  );
}

export async function createSessionToCancelSubscription(
  accessToken: string,
  stripeSubscriptionId: string,
  stripeCustomerId: string,
  returnUrl: string,
) {
  return callApi(
    accessToken,
    `${subscriptionsEndpoint}stripe/${stripeSubscriptionId}`,
    'DELETE',
    { stripeCustomerId: stripeCustomerId, returnUrl: returnUrl },
  );
}
