import {
  Invoice,
  InvoiceContribution,
  Subscription,
} from '../types/interfaces';
import { CurrencyType, SolutionType, SubscriptionStatus } from '../types/enums';

/**
 * Calculates the total volume of carbon removal from invoice contributions.
 * @param {InvoiceContribution[]} invoiceContributions - Array of invoice contributions.
 * @returns {SolutionTypeAggregation[]} Array of solution type aggregations.
 */
export const getSolutionTypeAggregations = (
  invoiceContributions: InvoiceContribution[],
) => {
  const total =
    calculateTotalKgCarbomRemovalFromInvoiceContributions(invoiceContributions);
  const solutionTypeToInvoiceContributions =
    getSolutionTypeToInvoiceContributions(invoiceContributions);
  calculatePercentages(solutionTypeToInvoiceContributions, total);
  return Object.values(solutionTypeToInvoiceContributions).sort(
    (a, b) => b.totalVolume - a.totalVolume,
  );
};

/**
 * Calculates the total kilograms of carbon removal all time and current year.
 *
 * @param {Invoice[]} data - Array of invoices.
 * @returns {Object} An object containing the total kilograms of carbon removal and the total for the current year.
 * @property {number} totalKgCarbonRemoval - Total kilograms of carbon removal.
 * @property {number} totalKgCarbonRemovalCurrentYear - Total kilograms of carbon removal for the current year.
 */
export const calculateKgCarbonRemoval = (data: Invoice[]) => {
  const currentYear = new Date().getFullYear();
  let totalKgCarbonRemoval = 0;
  let totalKgCarbonRemovalCurrentYear = 0;

  data.forEach((invoice) => {
    totalKgCarbonRemoval += invoice.kgCarbonRemoval;
    if (new Date(invoice.purchaseDate).getFullYear() === currentYear) {
      totalKgCarbonRemovalCurrentYear += invoice.kgCarbonRemoval;
    }
  });

  return {
    totalKgCarbonRemoval: Math.round(totalKgCarbonRemoval),
    totalKgCarbonRemovalCurrentYear: Math.round(
      totalKgCarbonRemovalCurrentYear,
    ),
  };
};

/**
 * Calculates the total currency spent by currency type.
 *
 * @param {Invoice[]} data - Array of invoices.
 * @returns {Record<CurrencyType, { totalCurrencySpent: number; totalCurrencySpentCurrentYear: number }>}
 *          A record of currency type to currency spend data containing total currency spent all time and for the current year.
 */
export const calculateCurrencySpentByCurrencyType = (data: Invoice[]) => {
  const currentYear = new Date().getFullYear();

  return data.reduce(
    (
      acc: Record<
        CurrencyType,
        { totalCurrencySpent: number; totalCurrencySpentCurrentYear: number }
      >,
      item: Invoice,
    ) => {
      updateCurrencySpendData(acc, item, currentYear);
      return acc;
    },
    {} as Record<
      CurrencyType,
      { totalCurrencySpent: number; totalCurrencySpentCurrentYear: number }
    >,
  );
};

/**
 * Calculates the total kilograms of carbon removal per month.
 *
 * @param {Subscription[]} subscriptions - Array of subscriptions.
 * @returns {number} Total kilograms of carbon removal per month.
 */
export const calculateMonthlyKgCarbonRemoval = (
  subscriptions: Subscription[],
): number => {
  const ongoingSubscriptions = subscriptions.filter(
    (subscription) =>
      !subscription.cancelAtPeriodEnd &&
      subscription.status === SubscriptionStatus.Active,
  );

  return Math.round(
    ongoingSubscriptions.reduce((acc: number, subscription: Subscription) => {
      return acc + subscription.kgCarbonRemoval;
    }, 0),
  );
};

/**
 * Calculates the total currency spend per month by currency type.
 *
 * @param {Invoice[]} data - Array of invoices.
 * @returns {Record<CurrencyType, number> }>}
 *          A record of currency type to currency spend per month.
 */
export const calculateMonthlySpendByCurrencyType = (
  subscriptions: Subscription[],
) => {
  const ongoingSubscriptions = subscriptions.filter(
    (subscription) =>
      !subscription.cancelAtPeriodEnd &&
      subscription.status === SubscriptionStatus.Active,
  );

  return ongoingSubscriptions.reduce(
    (acc: Record<CurrencyType, number>, item: Subscription) => {
      updateCurrencyMonthlySpendData(acc, item);
      return acc;
    },
    {} as Record<CurrencyType, number>,
  );
};

/* HELPER FUNCTION */

/**
 * Separates invoice contributions by solution type and calculates the total kilograms of carbon removal.
 * @param {InvoiceContribution[]} invoiceContributions - Array of invoice contributions.
 * @returns {Record<string, { type: SolutionType; totalVolume: number; percentage: number }>}
 *          A record of solution type to its total volume and percentage.
 */
const getSolutionTypeToInvoiceContributions = (
  invoiceContributions: InvoiceContribution[],
) =>
  invoiceContributions.reduce(
    (
      acc: Record<
        string,
        { type: SolutionType; totalVolume: number; percentage: number }
      >,
      item: InvoiceContribution,
    ) => {
      if (item.solutionType) {
        if (!acc[item.solutionType]) {
          acc[item.solutionType] = {
            type: item.solutionType,
            totalVolume: 0,
            percentage: 0,
          };
        }
        acc[item.solutionType].totalVolume = Math.round(
          acc[item.solutionType].totalVolume + item.kgCarbonRemoval,
        );
      }
      return acc;
    },
    {} as Record<
      string,
      { type: SolutionType; totalVolume: number; percentage: number }
    >,
  );

const calculatePercentages = (
  aggObj: Record<string, { totalVolume: number; percentage: number }>,
  total: number,
) => {
  Object.values(aggObj).forEach((sumObj) => {
    sumObj.percentage = Math.floor((sumObj.totalVolume / total) * 100);
  });
};

const calculateTotalKgCarbomRemovalFromInvoiceContributions = (
  invoiceContributions: InvoiceContribution[],
) => {
  return invoiceContributions.reduce(
    (total, item) =>
      Math.round(total + (item.solutionType ? item.kgCarbonRemoval : 0)),
    0,
  );
};

const updateCurrencySpendData = (
  acc: Record<
    CurrencyType,
    { totalCurrencySpent: number; totalCurrencySpentCurrentYear: number }
  >,
  item: Invoice,
  currentYear: number,
) => {
  if (!acc[item.currencyType]) {
    acc[item.currencyType] = {
      totalCurrencySpent: 0,
      totalCurrencySpentCurrentYear: 0,
    };
  }
  acc[item.currencyType].totalCurrencySpent += item.currencyAmount;
  if (new Date(item.purchaseDate).getFullYear() === currentYear) {
    acc[item.currencyType].totalCurrencySpentCurrentYear += item.currencyAmount;
  }
};

const updateCurrencyMonthlySpendData = (
  acc: Record<CurrencyType, number>,
  item: Subscription,
) => {
  if (!acc[item.currencyType]) {
    acc[item.currencyType] = 0;
  }
  acc[item.currencyType] += item.currencyAmount;
};
