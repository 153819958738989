import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Circle,
  Flex,
  Image,
  Link as ChakraLink,
  Heading,
  HStack,
  Text,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';

import {
  PortfolioPartnerData,
  SolutionTypeConfig,
  SolutionTypeInfo,
} from '../../types';

const Markdown = React.lazy(() => import('react-markdown'));

interface PartnerCardProps {
  partnerData: PortfolioPartnerData;
}

const PartnerCard = ({ partnerData }: PartnerCardProps) => {
  const solutionTypeInfo: SolutionTypeInfo =
    SolutionTypeConfig[partnerData.solutionType];
  const [isSmallScreen] = useMediaQuery('(max-width: 48em)');

  const renderHeader = () => {
    return (
      <CardHeader pb={1}>
        <Heading as="h2" size="lg">
          {partnerData.name}
        </Heading>
        <HStack spacing="2">
          <Circle size="8px" bg={solutionTypeInfo.color} />
          <Text>{solutionTypeInfo.name}</Text>
        </HStack>
      </CardHeader>
    );
  };

  const renderDescription = () => {
    return (
      <CardBody>
        <Stack spacing={3}>
          <Markdown>{partnerData.description}</Markdown>
          <Text>
            <ChakraLink
              href={partnerData.partnerInformationLink}
              isExternal
              color="teal"
            >
              {`Read more about ${partnerData.name}`}
            </ChakraLink>
          </Text>
        </Stack>
      </CardBody>
    );
  };

  const renderImageAndFacts = () => {
    return (
      <Box>
        <CardHeader>
          <Image
            src={partnerData.staticImageLink}
            alt={`Photo of ${partnerData.name}`}
            borderRadius="xl"
          />
        </CardHeader>
        <CardBody>
          <HStack spacing={2} w="100%" alignItems="start">
            <Stack w="50%" spacing={4}>
              <Text>
                Solution Type
                <br />
                <ChakraLink
                  color="teal.500"
                  as={ReactRouterLink}
                  to={`/solution-type/${partnerData.solutionType}`}
                >
                  <strong>{solutionTypeInfo.name}</strong>
                </ChakraLink>
              </Text>
              <Text>
                Permanence
                <br />
                <strong>{partnerData.permanence}</strong>
              </Text>
              <Text>
                Additionality
                <br />
                <strong>{partnerData.additionality}</strong>
              </Text>
            </Stack>

            <Stack w="50%" spacing={4}>
              <Text>
                Company Stage <br />
                <ChakraLink
                  color="teal.500"
                  href="https://zopeful.com/blog/carbon-removal-portfolio-fund-methodology"
                  isExternal
                >
                  <strong>{partnerData.companyStage}</strong>
                </ChakraLink>
              </Text>
              <Text>
                Location <br />
                <strong>{partnerData.location}</strong>
              </Text>
            </Stack>
          </HStack>
        </CardBody>
      </Box>
    );
  };

  const renderSmallScreenCard = () => {
    return (
      <Stack spacing={1} alignItems="start">
        {renderHeader()}
        {renderImageAndFacts()}
        {renderDescription()}
      </Stack>
    );
  };
  const renderCard = () => {
    return (
      <HStack spacing={2} alignItems="start">
        <Flex flex="1" direction="column">
          {renderHeader()}
          {renderDescription()}
        </Flex>
        <Flex flex="1" direction="column">
          {renderImageAndFacts()}
        </Flex>
      </HStack>
    );
  };

  return (
    <Card maxW="m" borderRadius="xl">
      {isSmallScreen ? renderSmallScreenCard() : renderCard()}
    </Card>
  );
};

export default PartnerCard;
