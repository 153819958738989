/* Solution Type / Partner objects */

import { SolutionType } from './enums';
import { SolutionTypeInfo } from './interfaces';

// TODO: Remove this after refactoring SortableTable
export const SolutionTypeConfig: Record<SolutionType, SolutionTypeInfo> = {
  [SolutionType.BIOCHAR_AND_BICRS]: {
    color: '#F1E583',
    name: 'Biochar/BICRS',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/biochar-and-bicrs-biomass-removal-and-carbon-storage',
  },
  [SolutionType.BIO_OIL]: {
    color: '#9CB0A4',
    name: 'Bio Oil',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/bio-oil-and-permanent-carbon-storage',
  },
  [SolutionType.BIODIVERSITY_AND_AVOIDED_DEFORESTATION]: {
    color: '#F59677',
    name: 'Biodiversity and Avoided Deforestation',
    permanenceOverFiveHundredYears: false,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/nature-based-solutions-for-carbon-removal',
  },
  [SolutionType.DIRECT_AIR_CAPTURE]: {
    color: '#28C181',
    name: 'Direct Air Capture',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/direct-air-capture-and-storage',
  },
  [SolutionType.ENHANCED_ROCK_WEATHERING]: {
    color: '#80CE77',
    name: 'Enhanced Rock Weathering',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/enhanced-rock-weathering',
  },
  [SolutionType.OCEAN_CARBON_REMOVAL]: {
    color: '#E3EBDC',
    name: 'Ocean Carbon Removal',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/ocean-carbon-removal-and-storage',
  },
  [SolutionType.REFORESTATION_AND_AFFORESTATION]: {
    color: '#BCDB77',
    name: 'Reforestation and Afforestation',
    permanenceOverFiveHundredYears: false,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/nature-based-solutions-for-carbon-removal',
  },
  [SolutionType.SOIL_CARBON_REMOVAL]: {
    color: '#D39C73',
    name: 'Soil Carbon Removal',
    permanenceOverFiveHundredYears: true,
    solutionTypeInformationLink:
      'https://zopeful.com/carbon-removal-portfolio/soil-carbon-removal',
  },
};
