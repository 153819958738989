import React, { useEffect, useState } from 'react';

import { Box, Heading } from '@chakra-ui/react';
import { Auth0ContextInterface, withAuth0 } from '@auth0/auth0-react';

import EmptyStateBlurb from '../../components/EmptyStateBlurb';
import LoadingWrapper from '../../components/LoadingWrapper';
import SortableTable, { Column } from '../../components/tables/SortableTable';
import CurrencyNumber from '../../utils/CurrencyNumber';
import { getInvoices } from '../../services/invoices';
import { Invoice } from '../../types/interfaces';

interface ContributionsTableState {
  isLoading: boolean;
  contributions: Invoice[];
  error: string | null;
}

const columns: Column[] = [
  { header: 'Purchase Name', accessor: 'purchaseName' },
  { header: 'Carbon Removal (KG)', accessor: 'kgCarbonRemoval' },
  { header: 'Purchase Date', accessor: 'purchaseDate' },
  { header: 'Total Amount', accessor: 'currencyAmount' },
];

const ContributionsTable = (props: { auth0: Auth0ContextInterface }) => {
  const [state, setState] = useState<ContributionsTableState>({
    isLoading: true,
    contributions: [],
    error: null,
  });

  useEffect(() => {
    const getContributionRowDataFromContribution = (contribution: Invoice) => {
      return {
        purchaseName: contribution.portfolio.name,
        kgCarbonRemoval: contribution.kgCarbonRemoval,
        purchaseDate: new Date(contribution.purchaseDate),
        currencyAmount: new CurrencyNumber(
          contribution.currencyAmount,
          contribution.currencyType,
          true,
        ),
      };
    };

    const fetchData = async () => {
      try {
        const token = await props.auth0.getAccessTokenSilently();

        const response = await getInvoices(token);

        const responseData = await response.json();

        if (!response.ok) {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            contributions: [],
            error: responseData.error,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            contributions: responseData.map(
              getContributionRowDataFromContribution,
            ),
          }));
        }
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          contributions: [],
          error: error.error,
        }));
      }
    };

    fetchData();
  }, [props.auth0, setState]);

  return (
    <Box width="100%">
      <Heading as="h2" pb="2vh" size="md">
        Carbon removal purchases
      </Heading>
      <LoadingWrapper isLoading={state.isLoading}>
        {state.contributions.length !== 0 ? (
          <SortableTable
            data={state.contributions}
            columns={columns}
            startingSortConfig={{
              key: 'purchaseDate',
              direction: 'descending',
            }}
          />
        ) : (
          <EmptyStateBlurb
            heading="Make your first contribution to carbon removal!"
            message="Join with a monthly subscription or a one-time purchase. Every tonne matters."
            link={{ to: '/purchase', text: 'Start your portfolio today' }}
          />
        )}
      </LoadingWrapper>
    </Box>
  );
};

export default withAuth0(ContributionsTable);
