import React, { useState } from 'react';

import { Auth0ContextInterface, withAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react';

import {
  createSessionToCancelSubscription,
  createSessionToUpdateSubscription,
} from '../../services/subscriptions';
import { CurrencyType } from '../../types/enums';

enum UpdateType {
  Update = 'Update',
  Cancel = 'Cancel',
}

const SubscriptionTableRowPopover = (props: {
  auth0: Auth0ContextInterface;
  stripeSubscriptionId: string;
  stripeCustomerId: string;
  stripeSubscriptionCurrency: CurrencyType;
  cancelAtPeriodEnd: boolean;
}) => {
  const [state, setState] = useState({
    updatingDisabled: false,
  });

  const toast = useToast();
  const failureToast = (updateType: UpdateType) =>
    toast({
      title: `Failed to ${
        updateType === UpdateType.Update ? 'update' : 'cancel'
      } your subscription.`,
      description: 'Reach out to hey@zopeful.com for help.',
      status: 'error',
      duration: 10000,
      isClosable: true,
    });

  const updateSubscription = async (updateType: UpdateType) => {
    try {
      setState((prevState) => ({
        ...prevState,
        updatingDisabled: true,
      }));

      const token = await props.auth0.getAccessTokenSilently();

      let response;
      if (updateType === UpdateType.Update) {
        response = await createSessionToUpdateSubscription(
          token,
          props.stripeSubscriptionId,
          props.stripeCustomerId,
          `${window.location.origin}${window.location.pathname}`,
        );
      } else {
        response = await createSessionToCancelSubscription(
          token,
          props.stripeSubscriptionId,
          props.stripeCustomerId,
          `${window.location.origin}${window.location.pathname}`,
        );
      }

      const responseData = await response.json();

      if (!response.ok) {
        failureToast(updateType);
        setState((prevState) => ({
          ...prevState,
          updatingDisabled: false,
        }));
      } else {
        window.location.href = responseData['url'];
      }
    } catch (error) {
      console.log(error);
      failureToast(updateType);
      setState((prevState) => ({
        ...prevState,
        updatingDisabled: false,
      }));
    }
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button>...</Button>
      </PopoverTrigger>
      <PopoverContent w="16rem" h="100%" mr="2rem">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody w="100%">
          <Box pb="12px" pt="25px">
            <Button
              w="100%"
              // Currently because of how Stripe subscriptions work/our configuration, we can only update
              // USD subscriptions with portal sessions.
              isDisabled={
                state.updatingDisabled ||
                props.stripeSubscriptionCurrency !== CurrencyType.USD
              }
              onClick={() => updateSubscription(UpdateType.Update)}
            >
              Manage Subscription
            </Button>
          </Box>
          <Box pb="12px">
            <Button
              w="100%"
              isDisabled={state.updatingDisabled || props.cancelAtPeriodEnd}
              onClick={() => updateSubscription(UpdateType.Cancel)}
            >
              Cancel Subscription
            </Button>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default withAuth0(SubscriptionTableRowPopover);
