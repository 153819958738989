import React, { useEffect, useState } from 'react';
import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import BasePage from '../../components/BasePage';
import LoadingWrapper from '../../components/LoadingWrapper';
import MethodologyBadges from '../../components/cards/MethodologyBadges';
import BackButton from '../../components/buttons/BackButton';
import InformationPageLinkCard from '../../components/cards/InformationPageLinkCard';
import PartnerCard from './PartnerCard';
import { SolutionType } from '../../types/enums';
import { PortfolioPartnerData, SolutionTypeData } from '../../types/interfaces';

type PartnerPageState = {
  partnerData: PortfolioPartnerData | null;
  similarPartners: PortfolioPartnerData[];
  solutionTypeData: SolutionTypeData | null;
  isLoading: boolean;
};

const PartnerPage = () => {
  const { partnerId } = useParams<{ partnerId: string }>();
  const [state, setState] = useState<PartnerPageState>({
    partnerData: null,
    similarPartners: [],
    solutionTypeData: null,
    isLoading: true,
  });

  useEffect(() => {
    const getPartnerData = (partnerList: PortfolioPartnerData[]) => {
      return (
        partnerList.find(
          (partner: PortfolioPartnerData) => partner.id === partnerId,
        ) || null
      );
    };
    const getSolutionTypeDataFromList = (
      solutionTypeList: SolutionTypeData[],
      solutionTypeId: SolutionType,
    ) => {
      return (
        solutionTypeList.find(
          (solutionType: SolutionTypeData) =>
            solutionType.id === solutionTypeId,
        ) || null
      );
    };

    const getSolutionTypeData = (solutionTypeId: SolutionType) => {
      fetch('/data/solutionTypeData.json')
        .then((response) => response.json())
        .then((jsonData) =>
          setState((prevState) => ({
            ...prevState,
            solutionTypeData: getSolutionTypeDataFromList(
              jsonData,
              solutionTypeId,
            ),
          })),
        )
        .catch((error) => {
          console.error(error);
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });
    };

    fetch('/data/partnerData.json')
      .then((response) => response.json())
      .then((jsonData) => {
        const partnerData = getPartnerData(jsonData.partnersInPortfolio);
        const solutionTypeId = partnerData?.solutionType;
        if (solutionTypeId !== undefined) {
          getSolutionTypeData(solutionTypeId);
        }

        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          partnerData: partnerData,
          similarPartners: jsonData.partnersInPortfolio.filter(
            (partner: PortfolioPartnerData) =>
              partner.solutionType === solutionTypeId &&
              partner.id !== partnerId &&
              partner.isActive,
          ),
        }));
      })
      .catch((error) => {
        console.error(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, [setState, partnerId]);

  const renderContent = () => {
    if (state.partnerData === null) {
      return (
        <Text>Partner not found. Please reach out to hey@zopeful.com</Text>
      );
    }
    return <PartnerCard partnerData={state.partnerData} />;
  };

  return (
    <BasePage>
      <BackButton text="Back to dashboard" url="/dashboard" />

      <Box paddingY={6}>
        <Heading as="h1" size="lg" pb="4">
          Suppliers
        </Heading>
        <LoadingWrapper isLoading={state.isLoading}>
          {renderContent()}
        </LoadingWrapper>
      </Box>

      <Box paddingY={6}>
        <Heading as="h1" size="lg" pb="4">
          Learn more
        </Heading>
        <Stack spacing={4}>
          {state.solutionTypeData ? (
            <InformationPageLinkCard
              imageLink={state.solutionTypeData.staticImageLink}
              heading={state.solutionTypeData.name || ''}
              description={state.solutionTypeData.shortDescription || ''}
              pageLink={`/solution-type/${state.solutionTypeData.id}`}
            />
          ) : null}
          {state.similarPartners.map((similarPartner: PortfolioPartnerData) => (
            <InformationPageLinkCard
              imageLink={similarPartner.staticImageLink}
              heading={similarPartner.name || ''}
              description={similarPartner.shortDescription || ''}
              pageLink={`/partner/${similarPartner.id}`}
              key={similarPartner.id}
            />
          ))}
        </Stack>
      </Box>

      <Box paddingY={6}>
        <Heading as="h1" size="lg" pb="4">
          Methodology
        </Heading>
        <MethodologyBadges />
      </Box>
    </BasePage>
  );
};

export default PartnerPage;
