import React, { useEffect, useState } from 'react';
import {
  Box,
  GridItem,
  Heading,
  Link as ChakraLink,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Auth0ContextInterface, withAuth0 } from '@auth0/auth0-react';

import EmptyStateBlurb from '../../components/EmptyStateBlurb';
import LoadingWrapper from '../../components/LoadingWrapper';
import SortableTable, { Column } from '../../components/tables/SortableTable';
import { getSolutionTypeAggregations } from '../../utils/aggregations';
import BasePage from '../../components/BasePage';
import DashboardStatCardSection from './stats/DashboardStatCardSection';
import DashboardEquivalenceSection from './DashboardEquivalenceSection';
import DashboardSolutionTypes from './DashboardSolutionTypes';
import DashboardContributionChart from './DashboardContributionsChart';
import { getInvoiceContributions, getInvoices } from '../../services/invoices';
import { getSubscriptions } from '../../services/subscriptions';
import {
  CarbonEquivalenceMilestone,
  Invoice,
  InvoiceContribution,
  PortfolioPartnerData,
  SolutionTypeAggregation,
  Subscription,
} from '../../types/interfaces';

type DashboardState = {
  equivalencesData: {
    milestones: CarbonEquivalenceMilestone[];
    isLoading: boolean;
  };
  invoiceContributionData: {
    data: InvoiceContribution[];
    solutionTypeAggegations: SolutionTypeAggregation[];
    isLoading: boolean;
  };
  invoiceData: {
    data: Invoice[];
    totalKGCarbonRemoved: number;
    isLoading: boolean;
  };
  portfolioPartnersData: {
    data: Invoice[];
    isLoading: boolean;
  };
  subscriptionData: {
    data: Subscription[];
    isLoading: boolean;
  };
};

const partnerTableColumns: Column[] = [
  { header: 'Partner', accessor: 'partner' },
  { header: 'Solution Type', accessor: 'solutionType' },
  { header: 'Company Stage', accessor: 'companyStage' },
  { header: 'Location', accessor: 'location' },
];

const DashboardPage = (props: { auth0: Auth0ContextInterface }) => {
  const [state, setState] = useState<DashboardState>({
    equivalencesData: {
      milestones: [],
      isLoading: true,
    },
    invoiceContributionData: {
      data: [],
      solutionTypeAggegations: [],
      isLoading: true,
    },
    invoiceData: {
      data: [],
      totalKGCarbonRemoved: 0,
      isLoading: true,
    },
    portfolioPartnersData: {
      data: [],
      isLoading: true,
    },
    subscriptionData: {
      data: [],
      isLoading: true,
    },
  });

  // Fetch equivalences data
  useEffect(() => {
    fetch('/data/equivalences.json')
      .then((response) => response.json())
      .then((jsonData) =>
        setState((prevState) => {
          return {
            ...prevState,
            equivalencesData: {
              equivalences: jsonData.equivalences,
              milestones: jsonData.milestones,
              isLoading: false,
            },
          };
        }),
      )
      .catch((error) => {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          equivalencesData: { ...prevState.equivalencesData, isLoading: false },
        }));
      });
  }, [setState]);

  // Fetch portfolio partners data
  useEffect(() => {
    const preprocessPartnersInPortfolio = (partner: PortfolioPartnerData) => {
      const partnerLink = (
        <ChakraLink
          color="teal.500"
          as={ReactRouterLink}
          to={`/partner/${partner.id}`}
        >
          {partner.name}
        </ChakraLink>
      );
      return {
        partner: partnerLink,
        solutionType: partner.solutionType,
        companyStage: partner.companyStage,
        location: partner.location,
      };
    };

    // Fetch portfolio partners data
    setState((prevState) => ({
      ...prevState,
      portfolioPartnersData: {
        ...prevState.portfolioPartnersData,
        isLoading: true,
      },
    }));
    fetch('/data/partnerData.json')
      .then((response) => response.json())
      .then((jsonData) =>
        setState((prevState) => ({
          ...prevState,
          portfolioPartnersData: {
            data: jsonData.partnersInPortfolio.map(
              preprocessPartnersInPortfolio,
            ),
            isLoading: false,
          },
        })),
      )
      .catch((error) => {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          portfolioPartnersData: {
            ...prevState.portfolioPartnersData,
            isLoading: false,
          },
        }));
      });
  }, [setState]);

  // Fetch invoice contributions data
  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({
        ...prevState,
        invoiceContributionData: {
          ...prevState.invoiceContributionData,
          isLoading: true,
        },
      }));
      try {
        const token = await props.auth0.getAccessTokenSilently();

        const response = await getInvoiceContributions(token);

        const data = await response.json();
        const solutionTypeAggregation: SolutionTypeAggregation[] =
          getSolutionTypeAggregations(data);

        if (response.ok) {
          setState((prevState) => ({
            ...prevState,
            invoiceContributionData: {
              data: data,
              solutionTypeAggegations: solutionTypeAggregation,
              isLoading: false,
            },
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          invoiceContributionData: {
            ...prevState.invoiceContributionData,
            isLoading: false,
          },
        }));
      }
    };

    fetchData();
  }, [props.auth0, setState]);

  // Fetch invoices data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await props.auth0.getAccessTokenSilently();

        const response = await getInvoices(token);

        const data = await response.json();

        if (response.ok) {
          const totalKGCarbonRemoved = data.reduce(
            (acc: number, { kgCarbonRemoval }: Invoice) =>
              acc + kgCarbonRemoval,
            0,
          );
          setState((prevState) => ({
            ...prevState,
            invoiceData: {
              data: data,
              totalKGCarbonRemoved: totalKGCarbonRemoved,
              isLoading: false,
            },
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          invoiceData: { ...prevState.invoiceData, isLoading: false },
        }));
      }
    };

    fetchData();
  }, [props.auth0, setState]);

  // Fetch subscriptions data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await props.auth0.getAccessTokenSilently();

        const response = await getSubscriptions(token);

        const data = await response.json();

        if (response.ok) {
          setState((prevState) => ({
            ...prevState,
            subscriptionData: { data: data, isLoading: false },
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          subscriptionData: { ...prevState.subscriptionData, isLoading: false },
        }));
      }
    };

    fetchData();
  }, [props.auth0, setState]);

  const renderPortfolioPartnersTable = () => {
    return (
      <LoadingWrapper isLoading={state.portfolioPartnersData.isLoading}>
        <SortableTable
          data={state.portfolioPartnersData.data}
          columns={partnerTableColumns}
        />
      </LoadingWrapper>
    );
  };

  const renderEmptyState = () => {
    return (
      <Box>
        <Box m={4} bg="white" borderRadius="xl">
          <EmptyStateBlurb
            heading="Make your first contribution to carbon removal!"
            message="Join with a monthly subscription or a one-time purchase. Every tonne matters."
            link={{ to: '/purchase', text: 'Start your portfolio today' }}
          />
        </Box>
        <Box p={4} m={4} bg="white" borderRadius="xl">
          <Heading size="md" as="h2" pb={2}>
            Suppliers in Portfolio-1
          </Heading>
          {renderPortfolioPartnersTable()}
        </Box>
      </Box>
    );
  };

  const renderData = () => {
    return (
      <Box display="flex" flexDirection="column" boxSizing="border-box">
        <SimpleGrid columns={[1, null, 2]} spacing={8} mb={4}>
          <LoadingWrapper
            isLoading={
              state.equivalencesData.isLoading || state.invoiceData.isLoading
            }
          >
            <DashboardEquivalenceSection
              milestones={state.equivalencesData.milestones}
              kgCarbonRemoved={state.invoiceData.totalKGCarbonRemoved}
            />
          </LoadingWrapper>

          <Box paddingY={4} paddingX={2} bg="white" borderRadius="xl">
            <Heading size="md" as="h2" pl={2}>
              Your contributions over time
            </Heading>
            <LoadingWrapper isLoading={state.invoiceData.isLoading}>
              <DashboardContributionChart data={state.invoiceData.data} />
            </LoadingWrapper>
          </Box>
        </SimpleGrid>

        <LoadingWrapper
          isLoading={
            state.invoiceData.isLoading || state.subscriptionData.isLoading
          }
        >
          <DashboardStatCardSection
            invoices={state.invoiceData.data}
            subscriptions={state.subscriptionData.data}
            numberMonthsToProject={[3, 6, 12]}
          />
        </LoadingWrapper>

        <SimpleGrid columns={[1, null, null, 10]} spacing={4} mb={4}>
          <GridItem
            colSpan={[1, 1, 1, 4]}
            h="100%"
            p={4}
            bg="white"
            borderRadius="xl"
          >
            <Heading size="md" as="h2">
              Portfolio by solution type
            </Heading>
            <LoadingWrapper isLoading={state.invoiceContributionData.isLoading}>
              <DashboardSolutionTypes
                data={state.invoiceContributionData.solutionTypeAggegations}
              />
            </LoadingWrapper>
          </GridItem>
          <GridItem colSpan={[1, 1, 1, 6]} h="100%">
            <Box p={4} bg="white" borderRadius="xl">
              <Heading size="md" as="h2">
                Suppliers in Portfolio-1
              </Heading>
              {renderPortfolioPartnersTable()}
            </Box>
          </GridItem>
        </SimpleGrid>
      </Box>
    );
  };

  return (
    <BasePage>
      <Stack spacing={8}>
        <Heading as="h2" size="lg">
          Dashboard
        </Heading>
        {!state.invoiceContributionData.isLoading &&
        state.invoiceContributionData.data.length === 0
          ? renderEmptyState()
          : renderData()}
      </Stack>
    </BasePage>
  );
};

export default withAuth0(DashboardPage);
