import React from 'react';

import DashboardStatCard from './DashboardStatCard';
import { calculateMonthlyKgCarbonRemoval } from '../../../utils/aggregations';
import { Subscription } from '../../../types/interfaces';

const ProjectedKgCarbonRemoval = (props: {
  subscriptions: Subscription[];
  numberMonthsToProject: number[];
}) => {
  const monthlyKgCarbonRemoval = calculateMonthlyKgCarbonRemoval(
    props.subscriptions,
  );

  const data = {
    label: 'Projected Contributions (kg)',
    tabs: props.numberMonthsToProject.map((months) => ({
      listTitle: `${months} mths`,
      statNumber: `${(months * monthlyKgCarbonRemoval).toLocaleString()} kg`,
      isIncrease: true,
    })),
  };

  return <DashboardStatCard label={data.label} tabs={data.tabs} />;
};

export default ProjectedKgCarbonRemoval;
